import React, { useState, useRef, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    Stack,
    Box,
    Textarea,
    TagCloseButton,
    Tag,
    Checkbox,
    Grid,
    Switch,
    Text,
    useToast,
    Link,
    Select,
    Flex,
    FormHelperText,
    Divider,
    Spinner,
} from '@chakra-ui/react';
import { MultiSelect } from 'react-multi-select-component';
import axiosHelper from '../../../core/axios-helper';
import { navigate } from 'gatsby';
import { LoadingMessage } from '../../../components';
import { getMerchantID } from '../../../core/auth';
import styled from 'styled-components';
import { partnerHubServiceBaseUrl } from '../../../core/config';
import { getGMTTimeString } from './utils/utils';

const ThickBlackDivider = styled.hr`
  border: none;
  height: 2px; /* Adjust thickness as needed */
  background-color: black;
  width: 100%; /* Ensure it spans the full width */
  margin: 20px 0; /* Add margin for spacing */
`;


// const MerchantModalWrapper = ({
//     isOpen,
//     onClose,
//     merchants,
//     roleOptions,
//     selectedMerchants,
//     setSelectedMerchantes,
//     merchantRoles,
//     setMerchantRoless,
//     merchantStatus,
//     setMerchantStatus,
// }) => {
//     const [localSelectedMerchants, setLocalSelectedMerchants] = useState([]);
//     const [localSelectedRoles, setLocalSelectedRoles] = useState([]);
//     const [localMerchantRoles, setLocalMerchantRoles] = useState({});
//     const [selectAll, setSelectAll] = useState(false);
//     const finalFocusRef = useRef(null);
//     const [localMerchantStatus, setLocalMerchantStatus] = useState({});
//     const merchantID = getMerchantID();
//     useEffect(() => {
//         if (isOpen) {
//             setLocalSelectedMerchants(selectedMerchants);
//             setLocalMerchantRoles(merchantRoles);
//             setLocalMerchantStatus(merchantStatus);
//             const allRoles = [];
//             const sanitizedMerchantRoles = {};
//             for (const merchantId in merchantRoles) {
//                 sanitizedMerchantRoles[merchantId] = merchantRoles[merchantId] === null ? [] : merchantRoles[merchantId];
//             }
//             Object.values(sanitizedMerchantRoles).forEach((roles) => {
//                 roles.forEach((role) => {
//                     if (!allRoles.includes(role)) {
//                         allRoles.push(role);
//                     }
//                 });
//             });
//             setLocalSelectedRoles(roleOptions.filter((option) => allRoles.includes(option.value)));
//         }
//     }, [isOpen, selectedMerchants, merchantRoles, merchantStatus, roleOptions]);

//     const handleSelectAll = (isChecked) => {
//         setSelectAll(isChecked);
//         if (isChecked) {
//             const allMerchantIds = merchants.map((merchant) => merchant.id);
//             setLocalSelectedMerchants(allMerchantIds);
//             const newMerchantRoles = {};
//             allMerchantIds.forEach((merchantId) => {
//                 newMerchantRoles[merchantId] = localSelectedRoles.map((role) => role.value);
//             });
//             setLocalMerchantRoles(newMerchantRoles);
//             const newMerchantStatus = {};
//             allMerchantIds.forEach((merchantId) => {
//                 newMerchantStatus[merchantId] = true;
//             });
//             setLocalMerchantStatus(newMerchantStatus);
//         } else {
//             setLocalSelectedMerchants([]);
//             setLocalMerchantRoles({});
//             setLocalMerchantStatus({});
//         }
//     };

//     const handleMerchantToggle = (merchantId) => {
//         if (localSelectedMerchants.includes(merchantId)) {
//             setLocalSelectedMerchants(localSelectedMerchants.filter((id) => id !== merchantId));
//             setLocalMerchantRoles((prevRoles) => {
//                 const newRoles = { ...prevRoles };
//                 delete newRoles[merchantId];
//                 return newRoles;
//             });
//             setLocalMerchantStatus((prevStatus) => {
//                 const newStatus = { ...prevStatus };
//                 delete newStatus[merchantId];
//                 return newStatus;
//             });
//         } else {
//             setLocalSelectedMerchants([...localSelectedMerchants, merchantId]);
//             setLocalMerchantRoles((prevRoles) => ({
//                 ...prevRoles,
//                 [merchantId]: localSelectedRoles.map((role) => role.value),
//             }));
//             setLocalMerchantStatus((prevStatus) => ({ ...prevStatus, [merchantId]: true }));
//         }
//     };
//     const handleRoleChange = (selected) => {
//         setLocalMerchantRoles((prevMerchantRoles) => {
//           const newMerchantRoles = { ...prevMerchantRoles }; // Create a copy of the previous roles

//           localSelectedMerchants.forEach((merchantId) => {
//             newMerchantRoles[merchantId] = selected.map((role) => role.value); // Update roles for selected merchants
//           });

//           return newMerchantRoles;
//         });

//         setLocalSelectedRoles(selected); // Update localSelectedRoles if needed
//       };

//     const handleRoleTagClose = (merchantId, roleToRemove) => {
//         setLocalMerchantRoles((prevRoles) => {
//             const updatedRoles = {
//                 ...prevRoles,
//                 [merchantId]: (prevRoles[merchantId] || []).filter((role) => role !== roleToRemove),
//             };
//             if ((updatedRoles[merchantId] || []).length === 0) {
//                 setLocalSelectedMerchants(localSelectedMerchants.filter((id) => id !== merchantId));
//                 setLocalMerchantStatus((prevStatus) => {
//                     const newStatus = { ...prevStatus };
//                     delete newStatus[merchantId];
//                     return newStatus;
//                 });
//             }
//             return updatedRoles;
//         });
//     };

//     const handleSubmit = () => {
//         function areAllMerchantsRolesValid(selectedMerchants, merchantRoles) {
//             const active = selectedMerchants.map((merchant) => localMerchantStatus[merchant.value])
//             for (const merchant of selectedMerchants) {
//                 const merchantId = merchant.value; // Get the merchant ID

//                 if (merchantId && (!merchantRoles[merchantId] || merchantRoles[merchantId].length === 0)) {
//                     return true; // Found a merchant with empty or null roles
//                 }
//             }
//             return false; // All merchants have valid roles
//         }
//         const allRolesValid = areAllMerchantsRolesValid(merchants, localMerchantRoles);
//         if (allRolesValid) {
//             alert('Please assign at least one role to each selected merchant.');
//             return;
//         }
//         setSelectedMerchantes(localSelectedMerchants);
//         setMerchantRoless(localMerchantRoles);
//         setMerchantStatus(localMerchantStatus);
//         onClose();
//     };

//     useEffect(() => {
//         if (localSelectedMerchants.length === merchants.length) {
//             setSelectAll(true);
//         } else {
//             setSelectAll(false);
//         }
//     }, [localSelectedMerchants, merchants]);

//     const handleStatusToggle = (merchantId) => {
//         setLocalMerchantStatus((prevStatus) => ({ ...prevStatus, [merchantId]: !prevStatus[merchantId] }));
//     };

//     return (
//         <Modal isOpen={isOpen} onClose={onClose} size="xl" finalFocusRef={finalFocusRef} closeOnOverlayClick={false}>
//             <ModalOverlay />
//             <ModalContent>
//                 <ModalHeader>Select Merchants and Roles</ModalHeader>
//                 <ModalCloseButton />
//                 <ModalBody>
//                     <Stack spacing={3}>
//                         <Checkbox isChecked={selectAll} onChange={(e) => handleSelectAll(e.target.checked)}>
//                             Select All
//                         </Checkbox>
//                         <FormControl>
//                             <MultiSelect
//                                 options={roleOptions.map((option) => ({ label: option.label, value: option.value }))}
//                                 value={localSelectedRoles}
//                                 onChange={handleRoleChange}
//                                 labelledBy="Select Roles"
//                             />
//                         </FormControl>
//                         {merchants.map((merchant) => (
//                             <Box key={merchant.id}>
//                                 <Checkbox
//                                     isChecked={localSelectedMerchants.includes(merchant.id)}
//                                     onChange={() => handleMerchantToggle(merchant.id)}
//                                 >
//                                     {merchant.name}
//                                 </Checkbox>
//                                 <Box ml={6} mt={2} display="flex" alignItems="center" flexWrap="wrap">
//                                 {(localMerchantRoles[merchant.id] || []).map((role) => (
//                                             (role != 'user admin') && (<Tag key={role} mr={2} mb={2} size="sm" colorScheme="gray" borderRadius="full">
//                                                 {role != 'user admin' && role}
//                                                 <TagCloseButton onClick={() => handleRoleTagClose(merchant.id, role)} />
//                                             </Tag>)
//                                         ))}
//                                     <Switch
//                                         size="md"
//                                         isChecked={localMerchantStatus[merchant.id] === true}
//                                         onChange={() => handleStatusToggle(merchant.id)}
//                                         ml={2}
//                                     />
//                                 </Box>
//                             </Box>
//                         ))}
//                     </Stack>
//                 </ModalBody>
//                 <ModalFooter>
//                     <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
//                         Save
//                     </Button>
//                     <Button onClick={onClose} ref={finalFocusRef}>
//                         Cancel
//                     </Button>
//                 </ModalFooter>
//             </ModalContent>
//         </Modal>
//     );
// };

const MerchantModalWrapper = ({
    isOpen,
    onClose,
    merchants,
    roleOptions,
    selectedMerchants,
    setSelectedMerchantes,
    merchantRoles,
    setMerchantRoless,
    merchantStatus,
    setMerchantStatus,
}) => {
    const [localSelectedMerchants, setLocalSelectedMerchants] = useState([]);
    const [localMerchantRoles, setLocalMerchantRoles] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const finalFocusRef = useRef(null);
    const [localMerchantStatus, setLocalMerchantStatus] = useState({});

    useEffect(() => {
        if (isOpen) {
          setLocalSelectedMerchants(selectedMerchants || []);
          setLocalMerchantStatus(merchantStatus || {});
    
          // Sanitize merchantRoles
          const sanitizedMerchantRoles = {};
          if (merchantRoles) {
            for (const merchantId in merchantRoles) {
              sanitizedMerchantRoles[merchantId] = merchantRoles[merchantId] === null ? [] : merchantRoles[merchantId];
            }
          }
          setLocalMerchantRoles(sanitizedMerchantRoles);
        }
    
        return () => {
          if (!isOpen) {
            setLocalSelectedMerchants([]);
            setLocalMerchantRoles({});
            setLocalMerchantStatus({});
          }
        };
      }, [isOpen, selectedMerchants, merchantRoles, merchantStatus]);

    // const handleSelectAll = (isChecked) => {
    //     setSelectAll(isChecked);
    //     if (isChecked) {
    //         const allMerchantIds = merchants.map((merchant) => merchant.id);
    //         setLocalSelectedMerchants(allMerchantIds);
    //         const newMerchantRoles = {};
    //         allMerchantIds.forEach((merchantId) => {
    //             newMerchantRoles[merchantId] = roleOptions.map((role) => role.value); // Set all roles for each merchant when select all
    //         });
    //         setLocalMerchantRoles(newMerchantRoles);
    //         const newMerchantStatus = {};
    //         allMerchantIds.forEach((merchantId) => {
    //             newMerchantStatus[merchantId] = true;
    //         });
    //         setLocalMerchantStatus(newMerchantStatus);
    //     } else {
    //         setLocalSelectedMerchants([]);
    //         setLocalMerchantRoles({});
    //         setLocalMerchantStatus({});
    //     }
    // };
    const handleSelectAll = (isChecked) => {
        setSelectAll(isChecked);
        if (isChecked) {
          const allMerchantIds = merchants.map((merchant) => merchant.id);
          setLocalSelectedMerchants(allMerchantIds);
      
          setLocalMerchantRoles((prevMerchantRoles) => {
            const newMerchantRoles = { ...prevMerchantRoles };
      
            allMerchantIds.forEach((merchantId) => {
              const existingRoles = prevMerchantRoles[merchantId] || [];
      
              if (existingRoles.length === 0) {
                // If no roles exist, add 'order specialist'
                newMerchantRoles[merchantId] = ['user'];
              } else {
                  // Preserve existing roles, including 'user admin'
                  newMerchantRoles[merchantId] = existingRoles;
              }
            });
      
            return newMerchantRoles;
          });
      
          const newMerchantStatus = {};
          allMerchantIds.forEach((merchantId) => {
            newMerchantStatus[merchantId] = true;
          });
          setLocalMerchantStatus(newMerchantStatus);
        } else {
          setLocalSelectedMerchants([]);
        //   setLocalMerchantRoles({});
          setLocalMerchantStatus({});
        }
      };
      const handleMerchantToggle = (merchantId) => {
        if (localSelectedMerchants.includes(merchantId)) {
          // Merchant is being deselected
          setLocalSelectedMerchants(localSelectedMerchants.filter((id) => id !== merchantId));
        //   setLocalMerchantRoles((prevRoles) => {
        //     const newRoles = { ...prevRoles };
        //     delete newRoles[merchantId];
        //     return newRoles;
        //   });
          setLocalMerchantStatus((prevStatus) => {
            const newStatus = { ...prevStatus };
            delete newStatus[merchantId];
            return newStatus;
          });
        } else {
          // Merchant is being selected
          setLocalSelectedMerchants([...localSelectedMerchants, merchantId]);
          setLocalMerchantRoles((prevRoles) => {
            if (prevRoles[merchantId] && prevRoles[merchantId].length > 0) {
              // Merchant has existing roles, preserve them
              return {
                ...prevRoles,
              };
            } else {
              // Merchant has no existing roles, add only "Order Specialist"
              return {
                ...prevRoles,
                [merchantId]: ['user'],
              };
            }
          });
          setLocalMerchantStatus((prevStatus) => ({ ...prevStatus, [merchantId]: true }));
        }
      };

    // const handleRoleChange = (selected, merchantId) => {
    //     setLocalMerchantRoles((prevMerchantRoles) => ({
    //         ...prevMerchantRoles,
    //         [merchantId]: selected.map((role) => role.value),
    //     }));
    // };
  
    
    const handleRoleChange = (selected, merchantId) => {
        setLocalMerchantRoles((prevMerchantRoles) => {
          const newMerchantRoles = { ...prevMerchantRoles };
          const existingRoles = prevMerchantRoles[merchantId] || [];
      
          if (existingRoles.includes('user admin')) {
            newMerchantRoles[merchantId] = [
              'user admin',
              ...selected.map((role) => role.value).filter((role) => role !== 'user admin'),
            ];
          } else {
            newMerchantRoles[merchantId] = selected.map((role) => role.value);
          }
      
          return newMerchantRoles;
        });
      };

    const handleRoleTagClose = (merchantId, roleToRemove) => {
        setLocalMerchantRoles((prevRoles) => {
            const updatedRoles = {
                ...prevRoles,
                [merchantId]: (prevRoles[merchantId] || []).filter((role) => role !== roleToRemove),
            };
            if ((updatedRoles[merchantId] || []).length === 0) {
                setLocalSelectedMerchants(localSelectedMerchants.filter((id) => id !== merchantId));
                setLocalMerchantStatus((prevStatus) => {
                    const newStatus = { ...prevStatus };
                    delete newStatus[merchantId];
                    return newStatus;
                });
            }
            return updatedRoles;
        });
    };

    const handleSubmit = () => {
        function areAllMerchantsRolesValid(selectedMerchants, merchantRoles) {
            const active = selectedMerchants.map((merchant) => localMerchantStatus[merchant.value]);
            for (const merchant of selectedMerchants) {
                const merchantId = merchant.value;
                if (merchantId && (!merchantRoles[merchantId] || merchantRoles[merchantId].length === 0)) {
                    return true;
                }
            }
            return false;
        }
        const allRolesValid = areAllMerchantsRolesValid(merchants, localMerchantRoles);
        if (allRolesValid) {
            alert('Please assign at least one role to each selected merchant.');
            return;
        }
        setSelectedMerchantes(localSelectedMerchants);
        setMerchantRoless(localMerchantRoles);
        setMerchantStatus(localMerchantStatus);
        onClose();
    };

    useEffect(() => {
        if (localSelectedMerchants.length === merchants.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [localSelectedMerchants, merchants]);

    const handleStatusToggle = (merchantId) => {
        setLocalMerchantStatus((prevStatus) => ({ ...prevStatus, [merchantId]: !prevStatus[merchantId] }));
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl" finalFocusRef={finalFocusRef} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Select Merchants and Roles</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack spacing={3}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                sz='lg'
                                isChecked={selectAll}
                                onChange={(e) => handleSelectAll(e.target.checked)}
                            />
                            <span style={{ userSelect: 'none', marginLeft: '8px' }}>Select All - activates and assigns the default “User” role to all inactive stores</span>
                        </div>
                        {merchants.map((merchant) => (
                            <Box key={merchant.id}>
                                <Checkbox
                                    isChecked={localSelectedMerchants.includes(merchant.id)}
                                    onChange={() => handleMerchantToggle(merchant.id)}
                                >
                                    {merchant.name}
                                </Checkbox>
                                <Box ml={6} mt={2}>
                                    <FormControl>
                                        <MultiSelect
                                            options={roleOptions
                                                .filter((option) => option.value !== 'user admin')
                                                .map((option) => ({ label: option.label, value: option.value }))}
                                            value={(localMerchantRoles[merchant.id] || [])
                                                .filter((role) => role !== 'user admin') // Filter out "user admin" from selected values
                                                .map((role) => ({
                                                    label: roleOptions.find((opt) => opt.value === role)?.label || role,
                                                    value: role,
                                                }))}
                                            onChange={(selected) => handleRoleChange(selected, merchant.id)}
                                            labelledBy="Select Roles"
                                        />
                                    </FormControl>
                                    <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                                        {(localMerchantRoles[merchant.id] || []).map((role) => (
                                            (role != 'user admin') && (<Tag key={role} mr={2} mb={2} size="sm" colorScheme="gray" borderRadius="full">
                                                {role != 'user admin' && role}
                                                <TagCloseButton onClick={() => handleRoleTagClose(merchant.id, role)} />
                                            </Tag>)
                                        ))}
                                        <Switch
                                            size="md"
                                            isChecked={localMerchantStatus[merchant.id] === true}
                                            onChange={() => handleStatusToggle(merchant.id)}
                                            ml={2}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                        Save
                    </Button>
                    <Button onClick={onClose} ref={finalFocusRef}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default function UserEdit({ initialValues, merchants, missingMerchants,isHopSop, roleOptions }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMerchants, setSelectedMerchantes] = useState([]);
    const [merchantRoles, setMerchantRoless] = useState({});
    const [useDefaultStoreAccess, setUseDefaultStoreAccess] = useState(initialValues.isActive);
    const [merchantTraining, setMerchantTraining] = useState(initialValues.trainingCompletedAt);
    const [userName, setUserName] = useState(''); // Added username state
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstNameErrorText, setFirstNameErrorText] = useState('')
    const [lastNameErrorText, setLastNameErrorText] = useState('')
    const [email, setEmail] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [userNameError, setUserNameError] = useState();
    const [emailError, setEmailError] = useState()
    const [customStoreAccess, setCustomStoreAccess] = useState('');
    const [adminChecked, setAdminChecked] = useState(false);
    const [userChecked, setUserChecked] = useState(false);
    const [corporateChecked, setCorporateChecked] = useState(false);
    const [staffChecked, setStaffChecked] = useState(false);
    const [merchantStatus, setMerchantStatus] = useState({});
    const [storeStatus, setStoreStatus] = useState({});
    const [singleMerchantStatus, setSingleMerchantStatus] = useState()
    const [loading, setLoading] = useState()
    const toast = useToast();
    // const initialValues = {
    //     userName: 'john_doe',
    //     firstName: 'John',
    //     lastName: 'Doe',
    //     email: 'john.doe@example.com',
    //     selectedMerchants: ['merchantA'],
    //     merchantRoles: {
    //         merchantA: ['admin', 'user'],
    //         // merchantB: ['corporate'],
    //         // merchantC:[]
    //     },
    //     merchantStatus: {
    //         merchantA: true,
    //         // merchantB: true,
    //         // merchantC: false
    //     },
    //     storeStatus: {
    //         merchantA: 'yes',
    //         merchantB: 'yes',
    //     },
    // }

    useEffect(() => {
        if (initialValues) {
            setUserName(initialValues.email || '');
            setFirstName(initialValues.firstName || '');
            setLastName(initialValues.lastName || '');
            setEmail(initialValues.email || '');
            setUseDefaultStoreAccess(initialValues.isActive || false);
            setMerchantTraining((initialValues.trainingCompletedAt === '' || initialValues.trainingCompletedAt === false) ? false : true)
            setSelectedMerchantes(initialValues.selectedMerchants ? [...initialValues.selectedMerchants] : []);
            setMerchantRoless(initialValues.merchantRoles != null ? JSON.parse(JSON.stringify(initialValues.merchantRoles)) : {});
            setMerchantStatus(initialValues.merchantStatus ? JSON.parse(JSON.stringify(initialValues.merchantStatus)) : {});
            if (merchants.length === 1) {
                setAdminChecked(initialValues?.merchantRoles[merchants[0].id]?.includes('order specialist'));
                setUserChecked(initialValues?.merchantRoles[merchants[0].id]?.includes('user'));
                setCorporateChecked(initialValues?.merchantRoles[merchants[0].id]?.includes('corporate'));
                setStaffChecked(initialValues?.merchantRoles[merchants[0].id]?.includes('staff'));
                setSingleMerchantStatus(initialValues?.merchantStatus[merchants[0].id])
                setUseDefaultStoreAccess(initialValues.isActive);
                setMerchantTraining((initialValues.trainingCompletedAt === '' || initialValues.trainingCompletedAt === false) ? false : true)
            }
        } else {
            setUserName('');
            setFirstName('');
            setLastName('');
            setEmail('');
            setSelectedMerchantes([]);
            setMerchantRoless({});
            setMerchantStatus({})
        }
    }, [initialValues]);
    const handleStoreAccessToggle = () => {
        setUseDefaultStoreAccess(!useDefaultStoreAccess);
    };
    const handleTrainingCompleted = () => {
        setMerchantTraining(!merchantTraining);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // const roleOptions = [
    //     { value: 'order specialist', label: 'order specialist' },
    //     { value: 'corporate', label: 'Corporate' },
    //     { value: 'user', label: 'User' },
    //     { value: 'staff', label: 'Staff' },
    // ];

    const handleRoleTagClose = (merchantId, roleToRemove) => {
        setMerchantRoless((prevRoles) => {
            const updatedRoles = { ...prevRoles };
            if (updatedRoles[merchantId]) {
                const newRoles = updatedRoles[merchantId].filter((role) => role !== roleToRemove);
                updatedRoles[merchantId] = newRoles;

                if (newRoles.length === 0) {
                    setSelectedMerchantes((prevMerchants) => prevMerchants.filter((id) => id !== merchantId));
                    delete updatedRoles[merchantId];
                }
            }
            return updatedRoles;
        });
    };

    const handleStatusToggle = (merchantId) => {
        setMerchantStatus((prevStatus) => ({ ...prevStatus, [merchantId]: !prevStatus[merchantId] }));
    };
    const handleStoreStatusChange = (merchantId, status) => {
        setStoreStatus((prevStatus) => ({ ...prevStatus, [merchantId]: status }));
    };
    const handleSingleMerchant = () => {
        setSingleMerchantStatus(!singleMerchantStatus);
    }

    const submitForm = async () => {
        const gmtTimeString = getGMTTimeString();
        const isTextFieldsEmpty = firstName === '' && lastName === '' && email === '';
        if (firstName.length < 1) {
            setFirstNameError(true)
        }
        if (firstName.length > 32) {
            setFirstNameErrorText(true)
        }
        if (lastName.length < 1) {
            setLastNameError(true)
        }
        if (lastName.length > 32) {
            setLastNameErrorText(true)
        }
        //   if(!validateEmail(userName)){
        //     setUserNameError(true)
        //   }
        if (!validateEmail(email)) {
            setEmailError(true)
            return;
        }
        if (firstName === '' || firstName.length > 32 || lastName === '' || lastName.length > 32) {
            return;
        }
        if (merchants.length === 1) {
            // Single merchant logic
             let singleRoleCheck= isHopSop?  !adminChecked && !userChecked && !corporateChecked : !isTextFieldsEmpty && !adminChecked && !userChecked && !corporateChecked && !staffChecked
            if (isTextFieldsEmpty && singleRoleCheck) {
                toast({
                    title: 'Missing Fields',
                    description: 'Please fill all text fields and select at least one role.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top-right',
                });
                return;
            }
            if (!isTextFieldsEmpty && singleRoleCheck && initialValues?.merchantRoles[merchants[0].id]?.includes('user admin')===false) {
                toast({
                    title: 'Missing Fields',
                    description: 'Please select at least one role.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top-right',
                });
                return;
            }
            if (isTextFieldsEmpty && (adminChecked || userChecked || corporateChecked || staffChecked)) {
                toast({
                    title: 'Missing Fields',
                    description: 'Please fill all text fields.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top-right',
                });
                return;
            }
        } else {
            // Multiple merchants logic
            const isMerchantRolesEmpty = Object.keys(merchantRoles).length === 0;
            const isMerchantSelectionEmpty = selectedMerchants.length === 0;

            if (isTextFieldsEmpty || isMerchantSelectionEmpty || isMerchantRolesEmpty) {
                let description = 'Please fill all the fields in and ensure at least one merchant and role is selected.';

                if (isTextFieldsEmpty && !isMerchantSelectionEmpty && !isMerchantRolesEmpty) {
                    description = 'Please fill all the text fields.';
                } else if (!isTextFieldsEmpty && isMerchantSelectionEmpty && !isMerchantRolesEmpty) {
                    description = 'Please select at least one merchant.';
                } else if (!isTextFieldsEmpty && !isMerchantSelectionEmpty && isMerchantRolesEmpty) {
                    description = 'Please select at least one role.';
                } else if (isTextFieldsEmpty && isMerchantSelectionEmpty && isMerchantRolesEmpty) {
                    description = 'Please fill all the text fields, and select at least one merchant and role.';
                }

                toast({
                    title: 'Missing Fields',
                    description: description,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top-right',
                });
                return;
            }
        }
        const activeMerchants = selectedMerchants.filter((merchantId) => merchantStatus[merchantId]);
        const inactiveMerchants = selectedMerchants.filter((merchantId) => !merchantStatus[merchantId]);

        const activeMerchantRoles = {};
        activeMerchants.forEach((merchantId) => {
            activeMerchantRoles[merchantId] = merchantRoles[merchantId];
        });

        const inactiveMerchantRoles = {};
        inactiveMerchants.forEach((merchantId) => {
            inactiveMerchantRoles[merchantId] = merchantRoles[merchantId];
        });
        const currentDateGMT = new Date().toUTCString();
        const allMerchantsData = merchants.map((merchant) => {
            let empty = merchantRoles[merchant.id]
            if ((empty === null || empty === undefined || empty.length===0) && merchantStatus[merchant.id] === true) {
                toast({
                    title: `${merchant.id} is on active with roles kindly make inactive without roles or add roles to it by selecting more merchnats section on top section link`,
                    description: '',
                    status: 'error',
                    duration: 7000,
                    isClosable: true,
                    position: 'top-right',
                });
                // Return null or an error object to indicate the issue
                return null; // Or return { error: `Merchant ${merchant.id} has invalid roles and status` };
            } else {
                return { // Explicit return and parentheses for the object
                    createdAt: currentDateGMT,
                    merchantId: merchant.id,
                    userRoles: merchantRoles[merchant.id] || [],
                    isActive: merchantStatus[merchant.id] || false,
                };
            }
        }) // Filter out the null values
        let isEmpty = allMerchantsData.some(element => element === null)
        if (isEmpty) {
            return;
        }
        //If you returned an error object instead of null.
        //.filter(merchant => !merchant.error);
        const selectedMerchantIds = new Set(initialValues.selectedMerchants);

        // Combine selected merchants and new merchants based on conditions
        const combinedMerchants = merchants
            .filter(
                (merchant) =>
                    selectedMerchantIds.has(merchant.id) ||
                    (merchantStatus[merchant.id] === true && !selectedMerchantIds.has(merchant.id))
            )
            .map((merchant) => ({
                createdAt: currentDateGMT,
                merchantId: merchant.id,
                userRoles: merchantRoles[merchant.id] || [],
                isActive: merchantStatus[merchant.id] || false,
            }));
        const formData = {
            // userName, // Added username to form data
            firstName,
            lastName,
            email,
            isActive: useDefaultStoreAccess,
            trainingCompletedAt: merchantTraining === true && initialValues.trainingCompletedAt === '' ? gmtTimeString : merchantTraining === false?'':initialValues.trainingCompletedAt,
        };

        if (merchants.length === 1 || merchants.length === 0) {
            const roles = [];
            if (corporateChecked) {
                roles.push("corporate")
            }
            if (userChecked) {
                roles.push("user")
            }
            if (staffChecked) {
                roles.push("staff")
            }
            if (adminChecked) {
                roles.push("order specialist")
            }
            if(initialValues?.merchantRoles[merchants[0].id]?.includes('user admin')){
                roles.push("user admin")
            }
            formData.merchants = [
                {
                    "createdAt": new Date().toUTCString(),
                    "isActive": singleMerchantStatus,
                    "merchantId": merchants[0].name,
                    "userRoles": roles

                },
                ...missingMerchants
            ]

        } else {
            formData.merchants = [...combinedMerchants, ...missingMerchants];

        }
        setLoading(true);
        const response = await axiosHelper.withMerchantAuth.put(
            `${partnerHubServiceBaseUrl}/account`,
            JSON.stringify(formData)
        );
        if (response.status !== 200) {
            // logger.error("Error while fetching transactions", response.data);
            toast({
                title: 'Api Called Failed',
                description: response.data,
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top-right',
            });
            setLoading(false)
            return;
        }
        setLoading(false)
       window.location.href = '/merchant/manageusers/dashboard'
    };
    const handleUserNameChange = (e) => {
        const newUserName = e.target.value;
        setUserName(newUserName);
        if (!email) {
            setEmail(e.target.value);
        }
        setUserNameError(false)
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError(false);
    };
    return (
        <Box p={4} maxW="400px">
            <Stack spacing={6}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Text color="blue.500" mr={2}>
                        Active User:
                    </Text>
                    <Switch
                        size="md"
                        isChecked={useDefaultStoreAccess === true}
                        onChange={handleStoreAccessToggle}
                        mr={2}
                    />
                </Box>
                {/* <FormControl id="userName">
                        <FormLabel>User Name</FormLabel>
                        <Input
                          placeholder="User name"
                          value={userName}
                          onChange={handleUserNameChange}
                          isInvalid={!validateEmail(userName) && userName || userNameError===true}
                        />
                        
                        <FormHelperText>
                        Email Format (ie. user@domain.com)
                      </FormHelperText>
                      {(!validateEmail(userName) && userName || userNameError===true) && <Text color="red.500" fontSize="sm">Please enter a valid user name.</Text>}
                      </FormControl> */}

                <FormControl id="email">
                    <FormLabel>Email</FormLabel>
                    <Input
                        type="email"
                        placeholder="Email"
                        value={email}
                        disabled
                        onChange={handleEmailChange}
                        isInvalid={(!validateEmail(email) && email || emailError === true)}
                        errorBorderColor="red.500"
                    />
                    {(!validateEmail(email) && email || emailError === true) && <Text color="red.500" fontSize="sm">Please enter a valid email address.</Text>}
                </FormControl>

                <FormControl id="firstName">
                    <FormLabel>First Name</FormLabel>
                    <Input
                        placeholder="First name"
                        value={firstName}
                        onChange={(e) => {
                            setFirstName(e.target.value)
                            setFirstNameError(false)
                            if (e.target.value.length > 32) {
                                setFirstNameErrorText(true)
                            } else {
                                setFirstNameErrorText(false)
                            }
                        }}
                        isInvalid={firstNameError === true || firstNameErrorText === true}
                        errorBorderColor="red.500"
                    />
                    {firstNameError === true && <Text color="red.500" fontSize="sm">First name must have at least 1 character.</Text>}
                    {firstNameErrorText === true && <Text color="red.500" fontSize="sm">First name must not exceed more than 32 characters.</Text>}
                </FormControl>

                <FormControl id="lastName">
                    <FormLabel>Last Name</FormLabel>
                    <Input
                        placeholder="Last name"
                        value={lastName}
                        onChange={(e) => {
                            setLastName(e.target.value)
                            setLastNameError(false)
                            if (e.target.value.length > 32) {
                                setLastNameErrorText(true)
                            } else {
                                setLastNameErrorText(false)
                            }
                        }}
                        isInvalid={lastNameError === true}
                        errorBorderColor="red.500"
                    />
                    {lastNameError === true && <Text color="red.500" fontSize="sm">Last name must have at least 1 character.</Text>}
                    {lastNameErrorText === true && <Text color="red.500" fontSize="sm">Last name must not exceed more than 32 characters.</Text>}
                </FormControl>



                {merchants.length === 1 ? (
                    <FormControl id="storeAccess">
                        <FormLabel>Store Access</FormLabel>
                        <Input value={merchants[0].name} isReadOnly />
                        <Switch
                            size="md"
                            isChecked={singleMerchantStatus === true}
                            onChange={handleSingleMerchant}
                            mr={2}
                        />
                    </FormControl>
                ) : (
                    <Link color="blue.500" onClick={openModal}>
                        Select Multiple Merchants and Roles
                    </Link>
                )}
                {merchants.length > 1 && (
                    <Box bg="black.100" p={4} borderRadius="md">
                        {merchants.map((merchant) => (
                            <Box key={merchant.id} mb={2} display="flex" alignItems="center">
                                <Box flex="1">
                                    {merchant.name}:
                                    <Box ml={2} display="flex" flexWrap="wrap">
                                        {merchantRoles[merchant.id] &&
                                            merchantRoles[merchant.id].map((role) => (
                                                (role != 'user admin') && (<Tag key={role} mr={2} mb={2} size="sm" colorScheme="gray" borderRadius="full">
                                                    {role != 'user admin' && role}
                                                    <TagCloseButton onClick={() => handleRoleTagClose(merchant.id, role)} />
                                                </Tag>)

                                            ))}
                                    </Box>
                                    <Flex align="center">

                                        <Switch
                                            size="md"
                                            isChecked={merchantStatus[merchant.id] === true}
                                            onChange={() => handleStatusToggle(merchant.id)}
                                            ml={2}
                                        />


                                    </Flex>
                                    <ThickBlackDivider />
                                </Box>


                            </Box>
                        ))}
                    </Box>
                )}
                {merchants.length === 1 && (
                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                        <Box bg="black.100" p={4} borderRadius="md">
                            <Checkbox variant="solid" id="order specialist" value="order specialist" isChecked={adminChecked} onChange={(e) => setAdminChecked(e.target.checked)}>
                                Order Specialist
                            </Checkbox>
                            <Text fontSize="sm" mt={2}>
                                Ability to create and manage orders from Orders Dashboard.
                            </Text>
                        </Box>

                        <Box bg="black.100" p={4} borderRadius="md">
                            <Checkbox variant="solid" id="user" value="user" isChecked={userChecked} onChange={(e) => setUserChecked(e.target.checked)}>
                                User
                            </Checkbox>
                            <Text fontSize="sm" mt={2}>
                                Read-Only access to Orders Dashboard.
                            </Text>
                        </Box>

                        <Box bg="black.100" variant="solid" p={4} borderRadius="md">
                            <Checkbox id="corporate" value="corporate" isChecked={corporateChecked} onChange={(e) => setCorporateChecked(e.target.checked)}>
                                Corporate
                            </Checkbox>
                            <Text fontSize="sm" mt={2}>
                                Access to Reports including Settlements.
                            </Text>
                        </Box>

                        {isHopSop===false &&(<Box bg="black.100" p={4} borderRadius="md">
                            <Checkbox id="staff" variant="solid" value="staff" isChecked={staffChecked} onChange={(e) => setStaffChecked(e.target.checked)}>
                                Staff
                            </Checkbox>
                            <Text fontSize="sm" mt={2}>
                                Read-Only access to Orders Dashboard with ability to Create New Orders.
                            </Text>
                        </Box>)}
                    </Grid>
                )}
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Text color="blue.500" mr={2}>
                        Training Completed:
                    </Text>
                    <Switch
                        size="md"
                        isChecked={merchantTraining === true}
                        onChange={handleTrainingCompleted}
                        mr={2}
                    />
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Button colorScheme="blue" onClick={submitForm}>
                        {loading ? <Spinner /> : 'Finish'}
                    </Button>
                    <Button onClick={() => window.location.href = '/merchant/manageusers/dashboard'}>
                        Cancel
                    </Button>
                </Box>
            </Stack>

            <MerchantModalWrapper
                selectedMerchants={selectedMerchants}
                storeStatus={storeStatus}
                setSelectedMerchantes={setSelectedMerchantes}
                merchantRoles={merchantRoles}
                setMerchantRoless={setMerchantRoless}
                merchantStatus={merchantStatus}
                setMerchantStatus={setMerchantStatus}
                merchants={merchants}
                roleOptions={roleOptions}
                isOpen={isModalOpen}
                onClose={closeModal}
                initialValues={initialValues}
            />
        </Box>
    );
}

