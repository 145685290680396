import React, { useState, useEffect } from "react";
import { Container, Grid, Text } from "basis";
import { NavigationalLayout } from "../components";

import UserEdit from "./UserEdit";
import axiosHelper from "../../../core/axios-helper";
import { getMerchantID, getProfile } from "../../../core/auth";
import { partnerHubServiceBaseUrl } from "../../../core/config";
import { Flex, Spinner } from "@chakra-ui/react";
import { addMissingMerchantsToInitialValues, extractLastSegment, extractMerchantList, findMissingMerchantData, transformUserDataToInitialValues } from "./utils/utils";
import { Loading } from "../../../components";

const Scene = () => {
  const [merchants, setMerchants] = useState([]);
  const [initialValues, setIntialValues] = useState({})
  const [missingMerchants, setMissingMerchants] = useState()
  const [loading, setLoading] = useState();
  const merchantID = getMerchantID();
  const [roleOptions, setRoleOptions] = useState([
     { value: 'order specialist', label: 'order specialist' },
     { value: 'corporate', label: 'Corporate' },
     { value: 'user', label: 'User' },
     { value: 'staff', label: 'Staff' },
 ])
  const profile = getProfile();
  useEffect(async () => {
    const lastSegment = extractLastSegment();
    setLoading(true)
    const url = `${partnerHubServiceBaseUrl}/merchant/hierarchy/${merchantID}`
    const response = await axiosHelper.withMerchantAuth.get(
      url
    )
    const result = extractMerchantList(response.data)
    const userDataResponse = await axiosHelper.withMerchantAuth.get(
      `${partnerHubServiceBaseUrl}/account/${lastSegment}`
    )
    const InitialValues = transformUserDataToInitialValues(userDataResponse.data);
    const initialValues = addMissingMerchantsToInitialValues(
      InitialValues,
      result
    );
   
    const missingresult = findMissingMerchantData(result, initialValues.merchantRoles, initialValues.merchantStatus)
    setMerchants(result)
    setIntialValues(initialValues)
    setMissingMerchants(missingresult)
    if(profile?.isHopSop===true){
      setRoleOptions([
        { value: 'order specialist', label: 'order specialist' },
        { value: 'corporate', label: 'Corporate' },
        { value: 'user', label: 'User' }
    ])
    }else{
      setRoleOptions([
        { value: 'order specialist', label: 'order specialist' },
        { value: 'corporate', label: 'Corporate' },
        { value: 'user', label: 'User' },
        { value: 'staff', label: 'Staff' },
    ])
    }
    setLoading(false)
  }, [])
  return (
    <NavigationalLayout>
      <Grid preset="page" rowsGap={8}>

        <Grid.Item colSpan="all">
          <Text as="h3" textStyle="heading3">Edit User</Text>
        </Grid.Item>

        <Grid.Item colSpan="all">
          <Container bg="white" padding="8">
            {loading ? <Flex justifyContent="center" my={4}>
            <Loading />
            </Flex> : <UserEdit isHopSop={profile?.isHopSop} roleOptions={roleOptions}missingMerchants={missingMerchants} merchants={merchants} initialValues={initialValues}></UserEdit>}

          </Container>
        </Grid.Item>

      </Grid>
    </NavigationalLayout>
  );
};

export default Scene;
